import { useRouter } from 'next/router';

const usePreviousRoute = () => {
	const router = useRouter();
	const saveCurrentPath = () => {
		const currentPath = router.asPath;
		sessionStorage.setItem('currentPath', currentPath);
	};
	return { saveCurrentPath };
};

export default usePreviousRoute;
