import {getCssSizeValue, getHtmlFromRedactorField} from "@/utils/utils";
import styled from "styled-components";
import {BREAKPOINTS} from "@/styles/themeSettings";

const Wrapper = styled.div`
	.number {
		display: flex;
		justify-content: flex-end;
		color: ${({theme}) => theme.colors.chilliRed};
		margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};

		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			justify-content: flex-start;
			margin-bottom: ${getCssSizeValue(48, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			margin-bottom: ${getCssSizeValue(52)};
		}
	}

	.header {
		color: ${({theme}) => theme.colors.richBlue};
		margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
		width: 90%;

		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
			width: 100%;
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			margin-bottom: ${getCssSizeValue(8)};
		}
	}

	.description {
		color: ${({theme}) => theme.colors.richBlue};
        margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};

		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			margin-bottom: ${getCssSizeValue(52, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			margin-bottom: ${getCssSizeValue(50)};
		}
	}
`

export default function Content({ number, header, description }) {
	return (
		<Wrapper>
			<div
				className='number typo-strawford typo-strawford-07'>{number || ''}</div>
			<div className='header typo-borna typo-strawford-08'
				 dangerouslySetInnerHTML={getHtmlFromRedactorField(header || '')}/>
			<div className='typo-strawford typo-strawford-09 description'
				 dangerouslySetInnerHTML={getHtmlFromRedactorField(description || '')}/>
		</Wrapper>
	)
}