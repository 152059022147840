import {DIRECTIONS} from "@/components/Banners/Home/hooks";

export const mainCardVariants = {
	enter: (direction) => ({
		x: direction === DIRECTIONS.RIGHT ? "100%" : "-100%",
	}),
	center: {
		x: 0,
	},
	exit: (direction) => ({
		x: direction === DIRECTIONS.RIGHT ? "-100%" : "100%",
		transition: {
			duration: 0.15
		}
	}),
};

export const imageVariants = {
	enter: (direction) => ({
		x: direction === DIRECTIONS.RIGHT ? "100%" : "-100%",
	}),
	center: {
		x: 0,
		transition: {
			duration: 0.8,
			ease: [0.33, 1, 0.68, 1],
		}
	},
	exit: (direction) => ({
		x: direction === DIRECTIONS.RIGHT ? "-100%" : "100%",
		transition: {
			duration: 0.9,
			ease: [0.33, 1, 0.68, 1]
		}
	}),
};


export const itemsVariants = {
	enter: (direction) => ({
		opacity: 0
	}),
	center: {
		opacity: 1,
	},
	exit: (direction) => ({
		opacity: 0,
	}),
};