import {useBranch} from "@/hooks/useBranch";
import {useMemo} from "react";
import {GLOBAL_STATE} from "@/constants";

export default function useFilterDataByState(data, useGlobalState = false) {
	const { selectedOption } = useBranch()

	const filteredData = useMemo(() => {
		if(data.length <= 0) return [];
		const stateToFilterBy = useGlobalState ? GLOBAL_STATE : selectedOption
		return data.filter((item) => item.state.handle.toLowerCase() === stateToFilterBy);
	}, [data, selectedOption]);

	return useMemo(() => {
		if (!filteredData.length) return null;
		return filteredData[0] || null;
	}, [filteredData]);
}
