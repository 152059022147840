import {useEffect} from 'react';
import useScrollContext from "@/hooks/useScrollContext";

const Loading = () => {
	const scroll = useScrollContext()
	useEffect(() => {
		return () => {
			scroll.refresh()
		}
	}, []);
	return null
}
export default Loading;
