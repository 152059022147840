import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Image, {formatImageCMSData} from "@/components/common/Image";
import GridContainer from "@/components/common/GridContainer";
import {getCssSizeValue, getHtmlFromRedactorField} from "@/utils/utils";
import useEmblaCarousel from "embla-carousel-react";
import ClassNames from 'embla-carousel-class-names'
import {usePrevNextArrowButtons, useSelectedSnap, useSliderDirection} from "@/components/Banners/Home/hooks";
import {ICONS, IMAGE_FALLBACK} from "@/constants";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";
import useFilterDataByState from "@/hooks/useFilterDataByState";
import {useBranch} from "@/hooks/useBranch";
import {imageVariants, itemsVariants, mainCardVariants} from "@/components/Banners/Home/animate";
import {insetPaddingRightCss} from "@/styles/globalStyles";
import useDevice, {useIsDesktop, useIsMobile} from "@/hooks/useDevice";
import SimpleIconButton from "@/components/common/SimpleIconButton";
import Content from "@/components/Banners/Home/Content";
import MobileSlider from "@/components/Banners/Home/MobileSlider";
import Buttons from "@/components/Banners/Home/Buttons";
import useGlobals from "@/hooks/useGlobals";
import Autoplay from "embla-carousel-autoplay";

const Wrapper = styled.section`
    overflow: hidden;
    position: relative;
    height: 100svh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding-bottom: ${getCssSizeValue(64, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        padding-bottom: ${getCssSizeValue(48)};
    }
`;

const ImageWrapper = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
	touch-action: pan-y;
    pointerEvents: auto;
	figure {
        height: 100%;
    }
`;

const ActiveItemContainer = styled.div`
    position: relative;
    overflow: hidden;
    background-color: ${({theme}) => theme.colors.white};

    padding: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)} ${getCssSizeValue(20, BREAKPOINTS.MOBILE)} ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)} ${getCssSizeValue(20, BREAKPOINTS.MOBILE)} ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        padding: ${getCssSizeValue(32)} ${getCssSizeValue(36)} ${getCssSizeValue(24)} ${getCssSizeValue(36)};
    }
`;

const ActiveItemContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${({theme}) => theme.colors.richBlack};
    min-height: 35vh;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        min-height: 40vh;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            //min-height: ${getCssSizeValue(460)};
    }
`

const NonActiveContainer = styled.div`
    display: none;
    height: fit-content;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        display: block;
        background-color: ${({theme}) => theme.colors.platinum};
        opacity: 0.95;
        height: ${getCssSizeValue(174, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        height: ${getCssSizeValue(174, BREAKPOINTS.DESKTOP)};
    }
`;

const EmblaViewPort = styled.div`
    overflow: hidden;
    height: 100%;
	opacity: 0;
	
	transition: all 0.3s var(--ease);
	
	&.visible {
		opacity: 1;
	}
`

const EmblaContainer = styled(motion.div)`
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    height: 100%;
`

const EmblaSlide = styled.div`
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        user-select: none;
        flex: 0 0 100%;
        min-width: 0;
        cursor: pointer;
        color: ${({theme}) => theme.colors.richBlack};
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: ${getCssSizeValue(15, BREAKPOINTS.TABLET)} ${getCssSizeValue(110, BREAKPOINTS.TABLET)} ${getCssSizeValue(20, BREAKPOINTS.TABLET)} ${getCssSizeValue(42, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        display: block;
        flex: 0 0 33%;
        padding: ${getCssSizeValue(15)} ${getCssSizeValue(40)} ${getCssSizeValue(19)} ${getCssSizeValue(43)};
    }

    div.number {
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            margin-bottom: ${getCssSizeValue(30)};
        }
    }
`

const Controls = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    gap: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        flex-direction: row;
        margin-left: unset;
        height: ${getCssSizeValue(174, BREAKPOINTS.TABLET)};
        background-color: ${({theme}) => theme.colors.platinum};
        padding-left: 2px;
        gap: ${getCssSizeValue(12)};
        justify-content: center;
        align-items: flex-end;
        opacity: 0.95;
        position: relative;
        padding-bottom: ${getCssSizeValue(17, BREAKPOINTS.TABLET)};
        ${insetPaddingRightCss};

        &::before {
            right: calc(100% - 1px);
            top: 0;
            content: '';
            position: absolute;
            width: calc(var(--grid-gap) + 2px);
            height: 100%;
            background-color: inherit;
            display: block;
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        height: ${getCssSizeValue(174)};
        padding-bottom: ${getCssSizeValue(44)};
    }
`;

function useGetCurrentStateData() {
	const allStates = useGlobals(state => state.states)
	const {selectedOption} = useBranch();

	return allStates.find(state => (state?.handle || '') === selectedOption)
}

export default function HomeBanner({data}) {
	const state = useGetCurrentStateData()
	const shouldGetGlobalSliderHomeBannerData = state?.shouldGetGlobalSliderHomeBannerData || false

	const isMobile = useIsMobile()
	const isDesktop = useIsDesktop()
	const {selectedOption} = useBranch();
	const filteredData = useFilterDataByState(data, shouldGetGlobalSliderHomeBannerData)
	const orientation = useDevice('orientation')
	const slideDuration = filteredData?.slideDuration || 5
	const items = useMemo(() => {
		return filteredData?.items || []
	}, [filteredData])

	const [emblaRef, emblaApi] = useEmblaCarousel({
		loop: true,
		align: 'start',
		startIndex: isMobile ? 0 : 1,
	}, [
		ClassNames(),
		Autoplay({delay: slideDuration * 1000, playOnInit: filteredData?.autoplay || false})
	])

	const selectedSnap = useSelectedSnap(emblaApi)
	const direction = useSliderDirection(emblaApi)

	const {
		onPrevButtonClick,
		onNextButtonClick
	} = usePrevNextArrowButtons(emblaApi)

	const [currentItem, setCurrentItem] = useState(items[0])

	const handleSlideClick = useCallback((index) => {
		if (!emblaApi) return;
		emblaApi.scrollTo(index + 1)
	}, [emblaApi])

	const itemsToMapOver = items?.slice(0) || []

	const currentImage = useMemo(() => {
		const image = orientation === 'landscape' ? currentItem?.image : currentItem?.imageVertical
		return formatImageCMSData(image || currentItem?.image || IMAGE_FALLBACK) //if vertical image is not set use default one or fallback
	}, [currentItem, orientation])

	const [hasSnapChanged, setHasSnapChanged] = useState(false)

	useEffect(() => {
		if (selectedSnap !== 0 || isMobile) {
			setHasSnapChanged(true);
		}

		if (!hasSnapChanged) {
			return
		}

		if (isMobile) {
			return setCurrentItem(items[selectedSnap])
		}
		setCurrentItem(selectedSnap === 0 ? items[items.length - 1] : items[selectedSnap - 1]);
	}, [selectedSnap, isMobile]);

	const [startX, setStartX] = useState(0);

	const handlePanStart = (event, info) => {
		setStartX(info.point.x);
	};

	const handlePanEnd = (event, info) => {
		if (isDesktop) return

		const endX = info.point.x;
		const deltaX = endX - startX;

		if (deltaX > 50) onPrevButtonClick();
		if (deltaX < -50) onNextButtonClick();
	};

	return (
		<Wrapper>
			<AnimatePresence mode='popLayout' custom={direction} initial={false}>
				<ImageWrapper
					key={currentItem?.id || ''}
					initial={hasSnapChanged ? "enter" : false}
					animate={hasSnapChanged ? "center" : false}
					exit={hasSnapChanged ? "exit" : false}
					custom={direction}
					variants={imageVariants}
					transition={{
						duration: 0.5,
					}}
					onPanStart={handlePanStart}
					onPanEnd={handlePanEnd}
				>
					<Image
						src={currentImage.url}
						alt={currentImage.alt}
						focalPoint={currentImage.focalPoint}
						placeholder={currentImage.blurUpThumb}
						sizes="100vw"
						quality={90}
						priority
					/>
				</ImageWrapper>
			</AnimatePresence>

			<GridContainer style={{position: "relative", zIndex: 1, alignItems: "end"}}>
				<ActiveItemContainer className='col-desktop-5 col-tablet-4 col-mobile-7'>
					<div style={{overflow: 'hidden'}}>
						{isMobile ? (
							<MobileSlider items={items} ref={emblaRef} currentItem={currentItem}
								filteredData={filteredData}/>
						) : (
							<AnimatePresence custom={direction} mode='wait' initial={false}>
								{currentItem && (
									<motion.div
										key={currentItem?.id || ''}
										custom={direction}
										initial={hasSnapChanged ? "enter" : false}
										animate={hasSnapChanged ? "center" : false}
										exit={hasSnapChanged ? "exit" : false}
										variants={mainCardVariants}
										transition={{
											duration: 0.5,
										}}
									>
										<ActiveItemContent>
											<Content number={currentItem?.number || ''}
												description={currentItem?.description || ''}
												header={currentItem?.header || ''}/>
											<Buttons currentItem={currentItem} filteredData={filteredData}/>
										</ActiveItemContent>
									</motion.div>
								)}
							</AnimatePresence>
						)}
					</div>
				</ActiveItemContainer>
				{!isMobile && (
					<NonActiveContainer className='col-desktop-10 col-tablet-3'>
						{itemsToMapOver.length && (
							<EmblaViewPort ref={emblaRef} className={hasSnapChanged ? 'visible' : ''}>
								<AnimatePresence mode='wait' initial={false}>
									<EmblaContainer
										key={selectedOption}
										initial="enter"
										animate="center"
										exit="exit"
										variants={itemsVariants}
										transition={{
											duration: 0.3,
										}}
									>
										{itemsToMapOver.map((item, index) => {
											return (
												<EmblaSlide
													key={item.id}
													onClick={() => handleSlideClick(index)}
												>
													<div
														className='number typo-borna typo-borna-15'>{item.number || ''}</div>
													<div className='typo-borna typo-borna-16'
														 dangerouslySetInnerHTML={getHtmlFromRedactorField(item.header || '')}/>
												</EmblaSlide>
											)
										})}
									</EmblaContainer>
								</AnimatePresence>
							</EmblaViewPort>
						)}
					</NonActiveContainer>
				)}

				<Controls className='col-desktop-1 col-tablet-1 col-mobile-1'>
					<SimpleIconButton
						onClick={onPrevButtonClick}
						name={ICONS.CHEVRON}
						color={colors.chilliRed}
						bgColor={isMobile ? colors.white : null} rotation={180}
						pointer
						fillColorAnimationParams={{
							enabled: true,
							color: colors.white,
							bgColor: colors.chilliRed,
							borderColor: colors.chilliRed
						}}
					/>
					<SimpleIconButton
						onClick={onNextButtonClick}
						name={ICONS.CHEVRON}
						color={colors.chilliRed}
						bgColor={isMobile ? colors.white : null}
						pointer
						fillColorAnimationParams={{
							enabled: true,
							color: colors.white,
							bgColor: colors.chilliRed,
							borderColor: colors.chilliRed
						}}
					/>
				</Controls>
			</GridContainer>
		</Wrapper>
	);
}
