import {useCallback, useEffect, useState} from "react";

export const DIRECTIONS = {
	LEFT: 'left',
	RIGHT: 'right',
}

export function useSliderDirection(emblaApi) {
	const [direction, setDirection] = useState(DIRECTIONS.RIGHT)
	const [lastSelectedSnap, setLastSelectedSnap] = useState(0)

	const selectListener = useCallback((emblaApi) => {
		const currentSelectedSnap = emblaApi.selectedScrollSnap();
		const slideNodesLength = emblaApi.slideNodes().length

		const isLoopOverAndNextSnapIsStart = (lastSelectedSnap === slideNodesLength - 1) && (currentSelectedSnap === 0)
		const isLoopOverAndNextSnapIsEnd = (lastSelectedSnap === 0) && (currentSelectedSnap === slideNodesLength - 1)

		if(isLoopOverAndNextSnapIsStart) {
			setDirection(DIRECTIONS.RIGHT)
		} else if(isLoopOverAndNextSnapIsEnd) {
			setDirection(DIRECTIONS.LEFT)
		} else {
			setDirection((lastSelectedSnap < currentSelectedSnap) ? DIRECTIONS.RIGHT : DIRECTIONS.LEFT)
		}

		setLastSelectedSnap(currentSelectedSnap);
	}, [lastSelectedSnap]);

	useEffect(() => {
		if (!emblaApi) return;

		emblaApi.on('select', selectListener);
		emblaApi.on('reInit', selectListener)

		return () => {
			emblaApi.off('select', selectListener);
			emblaApi.off('reInit', selectListener);
		};
	}, [emblaApi, selectListener]);

	return direction
}

export function useSelectedSnap (emblaApi)  {
	const [selectedSnap, setSelectedSnap] = useState(0)

	const updateScrollSnapState = useCallback((emblaApi) => {
		setSelectedSnap(emblaApi.selectedScrollSnap())
	}, [])

	useEffect(() => {
		if (!emblaApi) return

		updateScrollSnapState(emblaApi)
		emblaApi.on('select', updateScrollSnapState)
		emblaApi.on('reInit', updateScrollSnapState)

		return () => {
			emblaApi.off('select', updateScrollSnapState)
			emblaApi.off('reInit', updateScrollSnapState)
		}
	}, [emblaApi, updateScrollSnapState])

	return selectedSnap
}

export function usePrevNextArrowButtons(emblaApi, onClick) {
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

	const onPrevButtonClick = useCallback(() => {
		if (!emblaApi) return
		emblaApi.scrollPrev()
		if (onClick) onClick(emblaApi)
	}, [emblaApi, onClick])

	const onNextButtonClick = useCallback(() => {
		if (!emblaApi) return
		emblaApi.scrollNext()
		if (onClick) onClick(emblaApi)
	}, [emblaApi, onClick])

	const onSelect = useCallback((emblaApi) => {
		setPrevBtnDisabled(!emblaApi.canScrollPrev())
		setNextBtnDisabled(!emblaApi.canScrollNext())
	}, [])

	useEffect(() => {
		if (!emblaApi) return

		onSelect(emblaApi)
		emblaApi.on('reInit', onSelect).on('select', onSelect)
	}, [emblaApi, onSelect])

	return {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick
	}
}
