import React, {useMemo} from "react";
import HomeBanner from "@/components/Banners/Home/HomeBanner";
import Loading from "@/components/common/Loading";
import dynamic from 'next/dynamic';
import {getBannersData, getData, getLayoutData, getPageData} from "@/pages/api/api";
import {CMS_ELEMENT_TYPENAME, CONTENT_TYPES, PAGES} from "@/constants";
import {
	BANNER_CALL_TO_ACTION_PREVIEW_QUERY,
	BANNER_MODULE_PREVIEW_QUERY,
	DONOR_STORIES_QUERY,
	EXPANDABLE_VIDEO_QUERY,
	HEADER_AND_DESCRIPTION_MODULE_QUERY,
	NEWS_THUMBNAIL_HOME,
	PARTNERS_QUERY,
	RULES_TILES,
	SEO_QUERY,
	SINGLE_NEWS_QUERY,
	STATE_QUERY,
	STATES_MAP_QUERY,
	TEXT_AND_DESCRIPTION_WITH_BUTTON_QUERY,
	WHAT_WE_DO_PREVIEW_QUERY,
	WHAT_WE_DO_SINGLE_ACTION_QUERY,
} from "@/pages/api/fragments";
import 'instantsearch.css/themes/satellite.css';
import {getItemType} from "@/utils/utils";
import PreviewTag from "@/components/common/PreviewTag";

const WhatWeDoPreview = dynamic(() => import('@/components/WhatWeDoPreview'), {
	loading: () => <Loading />,
	// ssr: false
});

const ExpandingVideo = dynamic(() => import('@/components/expandingVideo/ExpandingVideo'), {
	loading: () => <Loading />,
	// ssr: false
});

const DonorStories = dynamic(() => import('@/components/DonorStories'), {
	loading: () => <Loading />,
	// ssr: false
});

const RulesTiles = dynamic(() => import('@/components/rulesTiles/RulesTiles'), {
	loading: () => <Loading />,
	// ssr: false
});

const NewsThumbnail = dynamic(() => import('@/components/newsThumbnail/NewsThumbnail'), {
	loading: () => <Loading />,
	// ssr: false
});

const NamesAndPaymentsCarousel = dynamic(() => import('@/components/NamesAndPaymentsCarousel'), {
	loading: () => <Loading />,
	// loading: () => null,
	// ssr: false
});

const StatesMapModule = dynamic(() => import('@/components/Maps'), {
	loading: () => <Loading />,
	// ssr: false
});

const Partners = dynamic(() => import('@/components/Partners'), {
	loading: () => <Loading />,
	// ssr: false
});

const TextAndDescriptionWithButton = dynamic(() => import('@/components/TextAndDescriptionWithButton'), {
	loading: () => <Loading />,
	// ssr: false
});

const BannerCallToActionPreviewModule = dynamic(() => import('@/components/BannerCallToActionModule/Preview'), {
	loading: () => <Loading />,
	ssr: false
})

const HeaderDescriptionModule = dynamic(() => import('@/components/Header&DescriptionModule'), {
	loading: () => <Loading/>,
	ssr: false
});

const BannerModule = dynamic(() => import('@/components/BannerModule'), {
	loading: () => <Loading/>,
	ssr: false
});


function setDataName(name){
	return name.replace(/Record/g, 'Data');
}

export default function Home({ data, banners, locale, preview=false}) {
	const seperatedData = useMemo(() => {
		const source = data?.content || []
		return source.reduce((previousValue, currentValue) => ({
			...previousValue,
			[setDataName(currentValue[CMS_ELEMENT_TYPENAME])] : currentValue
		}),{})
	},[data.content])

	const {
		WhatWeDoPreviewData,
		DonorStoriesModelData,
		NewsThumbnailData,
		RulesTilesModuleData,
		StatesMapData,
		ExpandableVideoPreviewData,
		TextAndDescriptionWithButtonData,
		PartnersModuleData,
		BannerCallToActionModulePreviewData,
		HeaderDescriptionModuleData,
		BannerModulePreviewData
	} = seperatedData

	return (
		<>
			<PreviewTag visible={preview}/>
			<HomeBanner data={banners} />
			<NamesAndPaymentsCarousel />
			<NewsThumbnail data={NewsThumbnailData}/>
			<BannerModule data={BannerModulePreviewData} />
			<WhatWeDoPreview data={WhatWeDoPreviewData}/>
			<RulesTiles data={RulesTilesModuleData} />
			<DonorStories data={DonorStoriesModelData}/>
			{PartnersModuleData && (
				<Partners data={PartnersModuleData}/>
			)}
			{TextAndDescriptionWithButtonData && (
				<TextAndDescriptionWithButton  data={TextAndDescriptionWithButtonData} />
			)}
			{HeaderDescriptionModuleData && (
				<HeaderDescriptionModule data={HeaderDescriptionModuleData} />
			)}
			<ExpandingVideo data={ExpandableVideoPreviewData}/>
			<StatesMapModule data={StatesMapData}/>
			<BannerCallToActionPreviewModule data={BannerCallToActionModulePreviewData} />
			{/*<ExtendedContent content={contentData}  />*/}
			{/*<SearchWrapper>*/}
			{/*	<h1>Search</h1>*/}
			{/*	/!*<Search/>*!/*/}
			{/*	/!*<MultiIndexSearch/>*!/*/}
			{/*	/!*<NewSearch/>*!/*/}
			{/*	/!*<RunScriptButton onClick={runScript}>RUN SCRIPT</RunScriptButton>*!/*/}
			{/*</SearchWrapper>*/}
		</>
	);
}

const HOME_PAGE_QUERY = `
	query GetHomePageData($locale: SiteLocale) {
		allSinglePages(locale: $locale, filter: {slug: {eq: "${PAGES.HOME}"}}) {
			id
			singlePageName
			slug
			${SEO_QUERY}
			content {
				__typename
				${WHAT_WE_DO_PREVIEW_QUERY}
				${NEWS_THUMBNAIL_HOME}
				${RULES_TILES}
				${DONOR_STORIES_QUERY}
				${EXPANDABLE_VIDEO_QUERY}
				${TEXT_AND_DESCRIPTION_WITH_BUTTON_QUERY}
				${STATES_MAP_QUERY}
				${BANNER_CALL_TO_ACTION_PREVIEW_QUERY}
				${HEADER_AND_DESCRIPTION_MODULE_QUERY}
				... on ${CONTENT_TYPES.PARTNERS}{
					${PARTNERS_QUERY}
				}
				${BANNER_MODULE_PREVIEW_QUERY}
			}
		}
	}
`;

const contextData = [
	// {
	// 	handle: 'actions',
	// 	model: CONTENT_TYPES.WHAT_WE_DO_PREVIEW,
	// },
	{
		handle: 'news',
		model: CONTENT_TYPES.NEWS_THUMBNAIL,
	},
]

const STATE_CONTEXT_QUERY = `
	query getData($locale: SiteLocale) {
	  allStates(locale: $locale) {
		${STATE_QUERY}
		actions: _allReferencingWhatWeDoSingleActions(first: 8, orderBy: order_DESC){
		  ${WHAT_WE_DO_SINGLE_ACTION_QUERY}
		   state: chooseState{
			${STATE_QUERY}
		  }
		  order
		}
		news: _allReferencingNews(first:4,orderBy: _createdAt_DESC) {
		  ${SINGLE_NEWS_QUERY}
		  order
		}
	  }
	}
`


function assignContextDataToContent(stateData=[], content = [], modelTypename='', handle='') {
	// remap data so structure matches old version
	const remapped = stateData.map((state) =>
		state[handle].map((el) =>
			({ ...el,  state: { id: state.id, name: state.name, handle: state?.handle || '' }})
		)
	).flat();
	//find element in content and apply data to it
	const target = content.find((item) => getItemType(item) === modelTypename)
	if(target){
		target[handle] = remapped
	}
}
export async function getStaticProps({ params, locale: loc, preview = false }) {
	const locale = loc || 'pl';
	const data = await getPageData('allSinglePages', PAGES.HOME, HOME_PAGE_QUERY, locale, '',preview);
	const banners = await getBannersData(locale, preview)
	const layout = await getLayoutData(locale, preview)
	const state = await getData('allStates', STATE_CONTEXT_QUERY, locale, preview); //get state context

	// logNamed({ preview })

	contextData.forEach((el) =>
		assignContextDataToContent(state, data.content, el.model, el.handle)
	)

	//TESTING

	return {
		props: {
			data,
			banners,
			locale,
			...layout,
			isDarkSearch: false,
			isHomePage: true,
			preview
		},
		revalidate: 60 * 2
	};
}
