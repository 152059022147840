import React from 'react';
import CommonButton from "@/components/common/Buttons/Button";
import {ICONS} from "@/constants";
import CommonIcon from "@/components/common/Icon/Icon";
import styled from "styled-components";

const Icon = styled(CommonIcon)`
	height: 100%;
`

const IconButton = ({ icon=ICONS.CHEVRON, color='', className= '', children, ...props }) => {
	return (
		<CommonButton className={`${className}`}{...props}>
			{children}
			<Icon name={icon} color={color}/>
		</CommonButton>
	);
};

export default IconButton;
