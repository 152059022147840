import React from 'react';
import styled from "styled-components";
import Text from "@/components/common/Text";

const Tag = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 13px 20px;
	top: 50%;
	left: 0;
	translate: 0 -50%;
	z-index: 1000;
	pointer-events: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    backdrop-filter: blur(8px);
	&:before{
		content: '';
		position: absolute;
		inset: 0;
        outline: solid 1px ${({ theme }) => theme.colors.white };
		border-radius: inherit;
        background-color: ${({ theme }) => theme.colors.richBlue };
		opacity: 0.75;
        
	}
`
const PreviewTag = ({ visible =false}) => {
	if(!visible) return
	return (
		<Tag>
			<Text color="white">
				Preview
			</Text>
		</Tag>
	);
};

export default PreviewTag;
