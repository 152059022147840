import styled from "styled-components";
import {forwardRef} from "react";
import Content from "@/components/Banners/Home/Content";
import Buttons from "@/components/Banners/Home/Buttons";

const EmblaViewPort = styled.div`
    overflow: hidden;
    height: 100%;
`

const EmblaContainer = styled.div`
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    height: 100%;
`

const EmblaSlide = styled.div`
	flex: 0 0 100%;
`

const MobileSlider = forwardRef(({ items, filteredData, currentItem }, ref) => {
	return (
		<div>
			<EmblaViewPort ref={ref}>
				<EmblaContainer>
					{items.map((item, index) => {
						return (
							<EmblaSlide key={index}>
								<Content header={item?.header || ''} description={item?.description || ''} number={item?.number || '01'}/>
							</EmblaSlide>
						)
					})}
				</EmblaContainer>
				<Buttons currentItem={currentItem} filteredData={filteredData} />
			</EmblaViewPort>
		</div>
	)
});

MobileSlider.displayName = 'MobileSlider';

export default MobileSlider;