import IconButton from "@/components/common/Buttons/IconButton";
import {BUTTON_VARIANTS, ICONS} from "@/constants";
import styled from "styled-components";
import usePreviousRoute from "@/hooks/usePreviousRoute";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {getCssSizeValue} from "@/utils/utils";
import LinkButton from "@/components/common/Buttons/LinkButton";
import {useMemo} from "react";
import {getButtonLinkData} from "@/components/common/Buttons/Button";

const Wrapper = styled.div`
    display: flex;

    gap: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        gap: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(16)};
    }

    .icon {
        width: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    }
`

export default function Buttons({ currentItem, filteredData }) {
	const {saveCurrentPath} = usePreviousRoute();
	const leftButtonText = filteredData?.activeCardLeftButtonText || "";
	const rightButtonText = filteredData?.activeCardRightButtonText || "";
	const leftButton = currentItem?.leftButton || {};

	const rightButton = useMemo(() => {
		const rightButton = currentItem?.rightButton || {};
		return {
			id: rightButton?.id || '',
			...getButtonLinkData(rightButton || {})
		}
	}, [currentItem])

	return (
		<Wrapper>
			{leftButton?.id && (
				<LinkButton
					data={leftButton}
					text={leftButton?.link?.text || leftButtonText} //use custom text or universal one
					variant={BUTTON_VARIANTS.SECONDARY}
				/>
			)}
			{rightButton?.url !== '' && (
				<IconButton
					as='link'
					href={rightButton.url}
					icon={ICONS.ARROW_RIGHT}
					onClick={saveCurrentPath}
					external={rightButton.isExternal}
					newCard={rightButton.newCard}
					aria-label={rightButton.ariaLabel}
				>
					{/* use custom text or universal one */}
					{rightButton.text || rightButtonText}
				</IconButton>
			)}
		</Wrapper>
	)
}